export default function ChatResources(){
    return (
        <section id="chat">
          <h3>Chat</h3>
          <ul>
            <li>
              <a href='https://chat.openai.com' target='_blank' rel="noreferrer">chat.openai.com</a>
              <span>ChatGPT login a reference chatbot</span>
            </li>
            <li>
              <a href='https://bard.google.com' target='_blank' rel="noreferrer">bard.google.com</a>
              <span>Google's new Chatbot to take on ChatGPT</span>
            </li>
            <li>
              <a href='https://t.me/marcbot' target='_blank' rel="noreferrer">t.me/marcbot</a>
              <span>An Al assistant that lives inside Telegram</span>
            </li>
            <li>
              <a href='https://easywithai.com/chatgpt-extensions/merlin' target='_blank' rel="noreferrer">easywithai.com/chatgpt-extensions/merlin</a>
              <span>OpenAI + ChatGPT power</span>
            </li>
            <li>
              <a href='https://mobile-gpt.io/chatgpt-blog' target='_blank' rel="noreferrer">mobile-gpt.io/chatgpt-blog</a>
              <span>ChatGPT on WhatsApp</span>
            </li>
            <li>
              <a href='https://monica.im' target='_blank' rel="noreferrer">monica.im</a>
              <span>ChatGPT-4 AI assistant for websites you visit</span>
            </li>
            <li>
              <a href='https://www.motion.ai' target='_blank' rel="noreferrer">motion.ai</a>
              <span>Build chatbots to do anything on any platform</span>
            </li>
            <li>
              <a href='https://chatspot.ai' target='_blank' rel="noreferrer">chatspot.ai</a>
              <span>All-in-one chat tool for growing better. - CRM</span>
            </li>
            <li>
              <a href='https://www.sheetai.app' target='_blank' rel="noreferrer">sheetai.app</a>
              <span>ChatGPT inside Google Sheets</span>
            </li>
            <li>
              <a href='https://chrome.google.com/webstore/detail/suggesty/hbiphmiliockggpepniplkkfmnhdihjh?ref=producthun' target='_blank' rel="noreferrer">Suggesty</a>
              <span>Get human-like answers to your Google searches with GPT-3</span>
            </li>
            <li>
              <a href='https://pathofex.com/chatgpt-dan' target='_blank' rel="noreferrer">pathofex.com/chatgpt-dan</a>
              <span>ChatGPT-4 Jailbreak full potential remove restrictions</span>
            </li>
            <li>
              <a href='https://www.berri.ai' target='_blank' rel="noreferrer">berri.ai</a>
              <span>Build production -ready chat GPT apps in minutes</span>
            </li>
            <li>
              <a href='https://chat.d-id.com' target='_blank' rel="noreferrer">chat.d-id.com</a>
              <span>Chat with avatar interface</span>
            </li>
          </ul>
        </section>
    )
}