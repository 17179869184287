export default function AutomateTasksResources(){
    return (
        <section id="automate-tasks">
          <h3>Automate Tasks</h3>
          <ul>
            <li>
              <a href='https://www.momentum.io' target='_blank' rel="noreferrer">momentum.io</a>
              <span>Al tool to help administer and automate sales calls</span>
            </li>
            <li>
              <a href='https://get.mem.ai' target='_blank' rel="noreferrer">get.mem.ai</a>
              <span>Al-powered workspace that's personalized to you</span>
            </li>
            <li>
              <a href='https://tryellie.com' target='_blank' rel="noreferrer">tryellie.com</a>
              <span>An email writing assistant that learns from your writing style</span>
            </li>
            <li>
              <a href='https://nanonets.com' target='_blank' rel="noreferrer">nanonets.com</a>
              <span>Automates manual data entry using Al</span>
            </li>
            <li>
              <a href='https://www.promptpal.net' target='_blank' rel="noreferrer">promptpal.net</a>
              <span>High-quality prompts that help you in work and life</span>
            </li>
            <li>
              <a href='https://publer.io' target='_blank' rel="noreferrer">publer.io</a>
              <span>Tool to create, schedule or analyse social posts on one platform</span>
            </li>
            <li>
              <a href='https://superhuman.com' target='_blank' rel="noreferrer">superhuman.com</a>
              <span>Automatically sort emails based on behavioural patterns</span>
            </li>
            <li>
              <a href='https://supernormal.com' target='_blank' rel="noreferrer">supernormal.com</a>
              <span>An Al-powered meeting recorder and writes your notes</span>
            </li>
            <li>
              <a href='https://tldv.io' target='_blank' rel="noreferrer">tldv.io</a>
              <span>Record meetings with Al, makes notes</span>
            </li>
            <li>
              <a href='https://waitroom.com' target='_blank' rel="noreferrer">waitroom.com</a>
              <span>Upgrade your meetings by Meeting moderation</span>
            </li>
            <li>
              <a href='https://www.getmagical.com' target='_blank' rel="noreferrer">getmagical.com</a>
              <span>Automate boring and repetitive tasks</span>
            </li>
            <li>
              <a href='https://poly.ai' target='_blank' rel="noreferrer">poly.ai</a>
              <span>Consumer led voice assistance, AI call centre</span>
            </li>
          </ul>
        </section>
    )
}