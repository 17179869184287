export default function ContentCreationResources(){
    return (
        <section id="content-creation">
          <h3>Content Creation</h3>
          <ul>
            <li>
              <a href='https://app.simplified.com' target='_blank' rel="noreferrer">app.simplified.com</a>
              <span>An Al writer for blogs, articles, ads, products, and websites</span>
            </li>
            <li>
              <a href='https://cohesive.so' target='_blank' rel="noreferrer">cohesive.so</a>
              <span>Create content with Al editor. - even wedding vows ;)</span>
            </li>
            <li>
              <a href='https://bearly.ai' target='_blank' rel="noreferrer">bearly.ai</a>
              <span>An Al tool to read, write and create content</span>
            </li>
            <li>
              <a href='https://www.coursebox.ai' target='_blank' rel="noreferrer">coursebox.ai</a>
              <span>Build complete courses that uniquely yours</span>
            </li>
            <li>
              <a href='https://app.decktopus.com' target='_blank' rel="noreferrer">app.decktopus.com</a>
              <span>Create compelling slides for your presentations with AI</span>
            </li>
            <li>
              <a href='https://ghostwryter.net' target='_blank' rel="noreferrer">ghostwryter.net</a>
              <span>An Al writing assistant made just for Google Docs</span>
            </li>
            <li>
              <a href='https://www.longshot.ai' target='_blank' rel="noreferrer">longshot.ai</a>
              <span>Accurate and optimized content writing, fact checking etc</span>
            </li>
            <li>
              <a href='https://stability.ai' target='_blank' rel="noreferrer">stability.ai</a>
              <span>Generate articles and blog posts in seconds</span>
            </li>
            <li>
              <a href='https://tome.app' target='_blank' rel="noreferrer">tome.app</a>
              <span>Make storytelling presentations with a click of a button</span>
            </li>
            <li>
              <a href='https://writesonic.com' target='_blank' rel="noreferrer">writesonic.com</a>
              <span>Create SEO-optimized content 10x faster</span>
            </li>
            <li>
              <a href='https://www.rezi.ai' target='_blank' rel="noreferrer">rezi.ai</a>
              <span>AI-Powered Resume Writing and Editing</span>
            </li>
            <li>
              <a href='https://www.wisdolia.com' target='_blank' rel="noreferrer">wisdolia.com</a>
              <span>Creates flashcards from any content</span>
            </li>
            <li>
              <a href='https://www.copy.ai' target='_blank' rel="noreferrer">copy.ai</a>
              <span>Automated content writing</span>
            </li>
            <li>
              <a href='https://www.gomoonbeam.com' target='_blank' rel="noreferrer">gomoonbeam.com</a>
              <span>A long-form writing assistant</span>
            </li>
            <li>
              <a href='https://spinbot.com' target='_blank' rel="noreferrer">spinbot.com</a>
              <span>AI powered paraphrasing tool</span>
            </li>
            <li>
              <a href='https://quillbot.com' target='_blank' rel="noreferrer">quillbot.com</a>
              <span>AI paraphrasing tool</span>
            </li>
          </ul>
        </section>
    )
}