export default function ContentAnalysisResources(){
    return (
        <section id="content-analysis">
          <h3>Content Analysis</h3>
          <ul>
            <li>
              <a href='https://www.kraftful.com' target='_blank' rel="noreferrer">kraftful.com</a>
              <span>Automated summaries of user reviews</span>
            </li>
            <li>
              <a href='https://base64.ai' target='_blank' rel="noreferrer">base64.ai</a>
              <span>Extract text, data, pics, & more from all types of docs using Al</span>
            </li>
            <li>
              <a href='https://www.perplexity.ai' target='_blank' rel="noreferrer">perplexity.ai</a>
              <span>Chrome extension that summarizes online articles</span>
            </li>
            <li>
              <a href='https://chatthing.ai' target='_blank' rel="noreferrer">chatthing.ai</a>
              <span>Create AI chatbots for use-cases using your existing data</span>
            </li>
            <li>
              <a href='https://chatdoc.com' target='_blank' rel="noreferrer">chatdoc.com</a>
              <span>Chat with documents. Get instant answers</span>
            </li>
            <li>
              <a href='https://chatbotkit.com' target='_blank' rel="noreferrer">chatbotkit.com</a>
              <span>Fastest way you make your own advanced chatbot</span>
            </li>
            <li>
              <a href='https://www.browse.ai' target='_blank' rel="noreferrer">browse.ai</a>
              <span>Let you scrape any website within 2 minutes</span>
            </li>
            <li>
              <a href='https://www.askviable.com' target='_blank' rel="noreferrer">askviable.com</a>
              <span>Automate your qualitative data analysis</span>
            </li>
            <li>
              <a href='https://www.glean.com' target='_blank' rel="noreferrer">glean.com</a>
              <span>AI-powered across all your company's apps</span>
            </li>
            <li>
              <a href='https://www.contractreader.io' target='_blank' rel="noreferrer">contractreader.io</a>
              <span>Read and understand  smart contracts with AI</span>
            </li>
            <li>
              <a href='https://www.quotifyai.com' target='_blank' rel="noreferrer">quotifyai.com</a>
              <span>Extract meaningful quotes from books and articles</span>
            </li>
            <li>
              <a href='https://parrot.ai' target='_blank' rel="noreferrer">parrot.ai</a>
              <span>Zoom or video meeting notes and analysis</span>
            </li>
            <li>
              <a href='https://www.pdfgpt.io' target='_blank' rel="noreferrer">pdfgpt.io</a>
              <span>Analyse and question large pdf documents</span>
            </li>
            <li>
              <a href='https://www.wordtune.com' target='_blank' rel="noreferrer">wordtune.com</a>
              <span>Summarize large documents per section</span>
            </li>
          </ul>
        </section>
    )
}