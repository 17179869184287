export default function AudioResources(){
    return (
        <section id="audio">
          <h3>Audio</h3>
          <ul>
            <li>
              <a href='https://www.castmagic.io' target='_blank' rel="noreferrer">www.castmagic.io</a>
              <span>10x Podcast Content With Al</span>
            </li>
            <li>
              <a href='https://murf.ai' target='_blank' rel="noreferrer">murf.ai</a>
              <span>Make studio-quality voiceovers from input text</span>
            </li>
            <li>
              <a href='https://podcast.adobe.com/enhance' target='_blank' rel="noreferrer">podcast.adobe.com/enhance</a>
              <span>Clean up audio in studio quality</span>
            </li>
            <li>
              <a href='https://beta.elevenlabs.io' target='_blank' rel="noreferrer">beta.elevenlabs.io</a>
              <span>Text to speech AI</span>
            </li>
            <li>
              <a href='https://soundraw.io' target='_blank' rel="noreferrer">soundraw.io</a>
              <span>AI music generator</span>
            </li>
            <li>
              <a href='https://mubert.com' target='_blank' rel="noreferrer">mubert.com</a>
              <span>AI generating music</span>
            </li>
            <li>
              <a href='https://wavtool.com' target='_blank' rel="noreferrer">wavtool.com</a>
              <span>Edit music with natural language</span>
            </li>
          </ul>
        </section>
    )
}