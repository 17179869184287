export default function MarketingResources(){
    return (
        <section id="marketing">
          <h3>Marketing</h3>
          <ul>
            <li>
              <a href='https://www.olli.ai' target='_blank' rel="noreferrer">olli.ai</a>
              <span>Custom business dashboards through enterprise AI</span>
            </li>
            <li>
              <a href='https://filtpod.com/engage-ai' target='_blank' rel="noreferrer">filtpod.com/engage-ai</a>
              <span>Use Al to write insightful comments on LinkedIn</span>
            </li>
            <li>
              <a href='https://www.jasper.ai' target='_blank' rel="noreferrer">jasper.ai</a>
              <span>Create blog posts & images marketing copy sales emails SEO etc</span>
            </li>
            <li>
              <a href='https://tome.app' target='_blank' rel="noreferrer">tome.app</a>
              <span>Create PowerPoints on topics of choice</span>
            </li>
          </ul>
        </section>
    )
}