import AudioResources from "./Audio";
import AutomateAdviceResources from "./AutomateAdvice";
import AutomateTasksResources from "./AutomateTasks";
import AvatarsResources from "./Avatars";
import ChatResources from "./Chat";
import ContentAnalysisResources from "./ContentAnalysis";
import ContentCreationResources from "./ContentCreation";
import DigitalDesignResources from "./DigitalDesign";
import MarketingResources from "./Marketing";
import ProductResearchResources from "./ProductResearch";
import ResearchTechResources from "./ResearchTech";
import VideoResources from "./Video";
import VisualDesignResources from "./VisualDesign";
import { Helmet } from 'react-helmet';

export default function OpenResources(){
    return (
        <div className="wrapper">
            <Helmet>
                <title>Open Resources - ProFun</title>
            </Helmet>
            <article id='resources'>
                <div className="links">
                    <a href="#avatars">Avatars</a>
                    <a href="#audio">Audio</a>
                    <a href="#automate-advice">Automated Advice</a>
                    <a href="#automate-tasks">Automated Tasks</a>
                    <a href="#chat" className="large">Chat</a>
                    <a href="#content-analysis" className="large">Content Analysis</a>
                    <a href="#content-creation" className="large">Content Creation</a>
                    <a href="#digital-design" className="large">Digital Design</a>
                    <a href="#marketing">Marketing</a>
                    <a href="#product-research">Product Research</a>
                    <a href="#research-tech" className="large">Research Tech</a>
                    <a href="#video">Video</a>
                    <a href="#visual-design" className="large">Visual Design</a>
                </div>
                <div className="col">
                    <AvatarsResources />
                    <AudioResources />
                    <AutomateAdviceResources />
                    <AutomateTasksResources />
                    <ChatResources />
                    <ContentAnalysisResources />
                    <ContentCreationResources />
                </div>
                <div className="col">
                    <DigitalDesignResources />
                    <MarketingResources />
                    <ProductResearchResources />
                    <ResearchTechResources />
                    <VideoResources />
                    <VisualDesignResources />
                </div>
            </article>
        </div>
    )
}