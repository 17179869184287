export default function VisualDesignResources(){
    return (
        <section id="visual-design">
          <h3>Visual Design</h3>
          <ul>
            <li>
              <a href='https://stablediffusionweb.com' target='_blank' rel="noreferrer">stablediffusionweb.com</a>
              <span>Stable Diffusion, create product</span>
            </li>
            <li>
              <a href='https://www.booth.ai' target='_blank' rel="noreferrer">booth.ai</a>
              <span>Create automatic pro quality product photography</span>
            </li>
            <li>
              <a href='https://www.blendnow.com' target='_blank' rel="noreferrer">blendnow.com</a>
              <span>Create professional product visuals in seconds</span>
            </li>
            <li>
              <a href='https://www.adcreative.ai' target='_blank' rel="noreferrer">adcreative.ai</a>
              <span>Ad creatives and social media post with AI</span>
            </li>
            <li>
              <a href='https://picassoia.com/generator/en' target='_blank' rel="noreferrer">picassoia.com/generator/en</a>
              <span>Let you create amazing artwork using Al</span>
            </li>
            <li>
              <a href='https://www.remove.bg' target='_blank' rel="noreferrer">remove.bg</a>
              <span>Remove the background from any image using Al</span>
            </li>
            <li>
              <a href='https://stockimg.ai' target='_blank' rel="noreferrer">stockimg.ai</a>
              <span>Generate stock images, wallpapers, and other visual assets</span>
            </li>
            <li>
              <a href='https://www.adobe.com/sensei/generative-ai/firefly.html' target='_blank' rel="noreferrer">adobe.com/sensei/generative-ai/firefly.html</a>
              <span>Infinite image creations</span>
            </li>
            <li>
              <a href='https://looka.com' target='_blank' rel="noreferrer">looka.com</a>
              <span>Design a logo for your brand, website, or company swag with Al</span>
            </li>
            <li>
              <a href='https://www.vizcom.ai' target='_blank' rel="noreferrer">vizcom.ai</a>
              <span>See your drawings come to life in seconds</span>
            </li>
            <li>
              <a href='https://lumalabs.ai' target='_blank' rel="noreferrer">lumalabs.ai</a>
              <span>Make 3D models from 2D pictures</span>
            </li>
            <li>
              <a href='https://www.artbreeder.com' target='_blank' rel="noreferrer">artbreeder.com</a>
              <span>Merging collages of images with prompt stories</span>
            </li>
            <li>
              <a href='https://magicstudio.com/magiceraser' target='_blank' rel="noreferrer">magicstudio.com/magiceraser</a>
              <span>Remove items from pictures with AI</span>
            </li>
            <li>
              <a href='https://www.reimaginehome.ai' target='_blank' rel="noreferrer">reimaginehome.ai</a>
              <span>Generate interior design ideas from pictures</span>
            </li>
            <li>
              <a href='https://www.nvidia.com' target='_blank' rel="noreferrer">nvidia.com</a>
              <span>Create pictures from basic drawings</span>
            </li>
            <li>
              <a href='https://www.versy.ai' target='_blank' rel="noreferrer">versy.ai</a>
              <span>Create building designs from text prompts, text to space</span>
            </li>
          </ul>
        </section>
    )
}