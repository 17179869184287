import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPaperPlaneTop } from "@fortawesome/pro-solid-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";
import { useState } from "react";

export default function NewsletterBox(){
    const [email, updateEmail] = useState("")
    const [status, setStatus] = useState<"idle" | "loading" | "success">("idle")

    function subscribe(e:React.FormEvent<HTMLFormElement>){
        e.preventDefault()
        setStatus("loading")
        const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify({ 
                query: "mutation { subscribeToNewsletter (newsletter: \"profun\", email:\""+ email +"\") }" 
            }),
          }

        fetch(process.env.REACT_APP_ACCOUNTS_URL!, options).then(() => {
            setStatus("success")
            setTimeout(() => {
                if (status === "success")
                    setStatus("idle")
            }, 4000)
        }).catch(e => {
            console.log(e)
            setStatus("idle")
        })
    }
    
    return <div className="newsletter-box">
        Subscribe below to be notified when new review presentations are uploaded:
        <form onSubmit={subscribe}>
            <input type="email" placeholder="Email address" value={email} onChange={(e) => { updateEmail(e.target.value) }} />
            <button type="submit" title="Submit" className={ status === "loading" ? "loading" : (status === "success" ? "success" : "")}>{ status === "loading" ? <FontAwesomeIcon icon={ faSpinnerThird } spin /> : (status === "idle" ? <FontAwesomeIcon icon={ faPaperPlaneTop } /> : <FontAwesomeIcon icon={ faCheck } />) }</button>
        </form>
    </div>
}