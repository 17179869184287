export default function DigitalDesignResources(){
    return (
        <section id="digital-design">
          <h3>Digital Design</h3>
          <ul>
            <li>
              <a href='https://www.slidesai.io' target='_blank' rel="noreferrer">slidesai.io</a>
              <span>Slides creator</span>
            </li>
            <li>
              <a href='https://www.diagram.com' target='_blank' rel="noreferrer">diagram.com</a>
              <span>AI in Figma</span>
            </li>
            <li>
              <a href='https://www.buzzy.buzz' target='_blank' rel="noreferrer">buzzy.buzz</a>
              <span>No-code platform into a Figma working app</span>
            </li>
            <li>
              <a href='https://www.buildai.space' target='_blank' rel="noreferrer">buildai.space</a>
              <span>Build AI apps in minutes</span>
            </li>
            <li>
              <a href='https://weaviate.io' target='_blank' rel="noreferrer">weaviate.io</a>
              <span>Open-source vector database</span>
            </li>
            <li>
              <a href='https://www.beautiful.ai' target='_blank' rel="noreferrer">beautiful.ai</a>
              <span>Generative Al presentation app</span>
            </li>
            <li>
              <a href='https://www.tabnine.com' target='_blank' rel="noreferrer">tabnine.com</a>
              <span>AI assistant for software developers</span>
            </li>
            <li>
              <a href='https://www.durable.co' target='_blank' rel="noreferrer">durable.co</a>
              <span>Make websites in 30 seconds</span>
            </li>
            <li>
              <a href='https://www.figma.com/design' target='_blank' rel="noreferrer">figma.com/design</a>
              <span>Design websites, applications, logos, etc</span>
            </li>
            <li>
              <a href='https://www.github.com/features/copilot' target='_blank' rel="noreferrer">github.com/features/copilot</a>
              <span>Co-pilot, Your AI pair programmer</span>
            </li>
            <li>
              <a href='https://www.midjourney.com' target='_blank' rel="noreferrer">midjourney.com</a>
              <span>Create design concepts from text prompts</span>
            </li>
            <li>
              <a href='https://productbot.ai' target='_blank' rel="noreferrer">productbot.ai</a>
              <span>Build software faster with automated documentation</span>
            </li>
            <li>
              <a href='https://www.uizard.io' target='_blank' rel="noreferrer">uizard.io</a>
              <span>Design stunning mock-ups in minutes</span>
            </li>
            <li>
              <a href='https://wand.ai' target='_blank' rel="noreferrer">wand.ai</a>
              <span>Self-service no code AI platform to generate AI</span>
            </li>
            <li>
              <a href='https://www.sitekick.ai' target='_blank' rel="noreferrer">sitekick.ai</a>
              <span>Create landing pages with AI in seconds</span>
            </li>
          </ul>
        </section>
    )
}