export default function ProductResearchResources(){
    return (
        <section id="product-research">
          <h3>Product Research</h3>
          <ul>
            <li>
              <a href='https://www.neuronsinc.com/predict' target='_blank' rel="noreferrer">neuronsinc.com/predict</a>
              <span>Predict customer responses - eye-tracking</span>
            </li>
            <li>
              <a href='https://userdoc.fyi' target='_blank' rel="noreferrer">userdoc.fyi</a>
              <span>AI-driven user personas, journeys and requirements</span>
            </li>
            <li>
              <a href='https://botif.ai' target='_blank' rel="noreferrer">botif.ai</a>
              <span>Create digital humans for engaging conversations. customer profiles</span>
            </li>
            <li>
              <a href='https://www.symanto.com' target='_blank' rel="noreferrer">symanto.com</a>
              <span>AI for social listening and other customer data</span>
            </li>
            <li>
              <a href='https://www.syntheticusers.com' target='_blank' rel="noreferrer">syntheticusers.com</a>
              <span>User research with AI participants</span>
            </li>
            <li>
              <a href='https://www.notably.ai' target='_blank' rel="noreferrer">notably.ai</a>
              <span>AI-powered user research platform</span>
            </li>
            <li>
              <a href='https://www.neuronsinc.com/predict' target='_blank' rel="noreferrer">neuronsinc.com/predict</a>
              <span>Predict customer responses and behaviour</span>
            </li>
          </ul>
        </section>
    )
}