export default function AutomateAdviceResources(){
    return (
        <section id="automate-advice">
          <h3>Automated Advice</h3>
          <ul>
            <li>
              <a href='https://www.studypalai.com ' target='_blank' rel="noreferrer">studypalai.com</a>
              <span>An Al-powered homework helper</span>
            </li>
            <li>
              <a href='https://www.digitalfirst.ai' target='_blank' rel="noreferrer">digitalfirst.ai</a>
              <span>Create and execute marketing plans with AI</span>
            </li>
            <li>
              <a href='https://www.dimeadozen.ai' target='_blank' rel="noreferrer">dimeadozen.ai</a>
              <span>Validate business, swot, trends, market, investment etc.</span>
            </li>
            <li>
              <a href='https://www.harvey.ai' target='_blank' rel="noreferrer">harvey.ai</a>
              <span>An AI-powered legal advisor</span>
            </li>
            <li>
              <a href='https://hints.so' target='_blank' rel="noreferrer">hints.so</a>
              <span>GPT-based assistant for productivity tools</span>
            </li>
            <li>
              <a href='https://www.getproduct.help' target='_blank' rel="noreferrer">getproduct.help</a>
              <span>AI assists users in making purchasing decisions</span>
            </li>
            <li>
              <a href='https://rationale.jina.ai' target='_blank' rel="noreferrer">rationale.jina.ai</a>
              <span>AI-assisted decision making</span>
            </li>
            <li>
              <a href='https://www.roamaround.io' target='_blank' rel="noreferrer">roamaround.io</a>
              <span>Plan your trips through an Al travel planner</span>
            </li>
            <li>
              <a href='https://www.roomsgpt.io' target='_blank' rel="noreferrer">roomsgpt.io</a>
              <span>Redesign your room with just a click of a button</span>
            </li>
            <li>
              <a href='https://validatorai.com' target='_blank' rel="noreferrer">validatorai.com</a>
              <span>AI Business validator for any idea</span>
            </li>
            <li>
              <a href='https://venturusai.com' target='_blank' rel="noreferrer">venturusai.com</a>
              <span>Instant feedback and analysis of business ideas</span>
            </li>
            <li>
              <a href='https://vizologi.com' target='_blank' rel="noreferrer">vizologi.com</a>
              <span>Get answers to all your business questions</span>
            </li>
            <li>
              <a href='https://www.caktus.ai' target='_blank' rel="noreferrer">caktus.ai</a>
              <span>AI to help with your homework</span>
            </li>
            <li>
              <a href='https://donotpay.com' target='_blank' rel="noreferrer">donotpay.com</a>
              <span>Legal advice AI</span>
            </li>
            <li>
              <a href='https://www.mixo.io' target='_blank' rel="noreferrer">mixo.io</a>
              <span>AI for launching startups</span>
            </li>
            <li>
              <a href='https://glass.health/ai' target='_blank' rel="noreferrer">glass.health/ai</a>
              <span>Medical advice</span>
            </li>
          </ul>
        </section>
    )
}