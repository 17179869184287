export default function VideoResources(){
    return (
        <section id="video">
          <h3>Video</h3>
          <ul>
            <li>
              <a href='https://www.clip.fm' target='_blank' rel="noreferrer">clip.fm</a>
              <span>Turn your videos into viral shorts with one click</span>
            </li>
            <li>
              <a href='https://2short.ai' target='_blank' rel="noreferrer">2short.ai</a>
              <span>Elevate your YouTube content with AI-generated shorts</span>
            </li>
            <li>
              <a href='https://maimovie.com' target='_blank' rel="noreferrer">maimovie.com</a>
              <span>Discover movies that match your preference</span>
            </li>
            <li>
              <a href='https://www.supercreator.ai' target='_blank' rel="noreferrer">supercreator.ai</a>
              <span>Create short-form videos with Al</span>
            </li>
            <li>
              <a href='https://usetrust.io' target='_blank' rel="noreferrer">usetrust.io</a>
              <span>AI tool to make video and text testimonials</span>
            </li>
            <li>
              <a href='https://www.quinv.io/ai' target='_blank' rel="noreferrer">quinv.io/ai</a>
              <span>Create video presentations using Al</span>
            </li>
            <li>
              <a href='https://invideo.io/ai' target='_blank' rel="noreferrer">invideo.io/ai</a>
              <span>Create videos from text prompts</span>
            </li>
            <li>
              <a href='https://kaiber.ai' target='_blank' rel="noreferrer">kaiber.ai</a>
              <span>Text to animation videos</span>
            </li>
            <li>
              <a href='https://runwayml.com' target='_blank' rel="noreferrer">runwayml.com</a>
              <span>Advanced video editing and creation with AI</span>
            </li>
            <li>
              <a href='https://www.myheritage.com/deep-nostalgia' target='_blank' rel="noreferrer">myheritage.com/deep-nostalgia</a>
              <span>3D video from old pics</span>
            </li>
          </ul>
        </section>
    )
}