import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import NewsletterBox from './components/Newsletter';
import ReactGA from "react-ga4";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePowerpoint } from '@fortawesome/pro-solid-svg-icons';

function App() {
  const location = useLocation()

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
  } else {
    ReactGA.initialize("G-0FL0T0SWRV")
  }

  function trackExternalLink(label:string){
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // dev code
    } else {
      ReactGA.event({
        category: "Conference Slides",
        action: "PPT Download",
        label: label
      })
    }
  }

  return (
    <>
      <header>
        <div className='wrapper'>
          <h1><span>PRO</span>FUN<small>.ai</small></h1>
          <h2>artificial creativity</h2>
        </div>
      </header>
      <Outlet />
      <footer>
        <div className='wrapper'>
          <NewsletterBox />
          <div className='presentation-links'>
            Download our review presentations:
            <ul>
              <li>
                <a onClick={ () => { trackExternalLink("ProFun Keynote AI23 embedded videos") } } href="https://cdn.profun.ai/PROFUN+Keynote+AI23+embedded+videos.pptx" target='_blank' rel='noreferrer'><FontAwesomeIcon icon={ faFilePowerpoint } /> Conference Slides LARGE (videos embedded 61 MB)</a>
              </li>
              <li>
                <a onClick={ () => { trackExternalLink("ProFun Keynote AI23 linked videos") } } href="https://cdn.profun.ai/PROFUN+Keynote+AI23+linked+videos.pptx" target='_blank' rel='noreferrer'><FontAwesomeIcon icon={ faFilePowerpoint } /> Conference Slides LIGHT (video links only 16 MB)</a>
              </li>
            </ul>
          </div>
        </div>
        <div className='copyright'>
          <div className='wrapper'>
            &copy; Aulive { new Date().getFullYear() }
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
