export default function ResearchTechResources(){
    return (
        <section id="research-tech">
          <h3>Research Tech</h3>
          <ul>
            <li>
              <a href='https://seenapse.it' target='_blank' rel="noreferrer">seenapse.it</a>
              <span>Generate dozens of divergent ideas</span>
            </li>
            <li>
              <a href='https://www.ibm.com/products/ip-advisor-with-watson' target='_blank' rel="noreferrer">ibm.com/products/ip-advisor-with-watson</a>
              <span>Tools for patent generation & analysis</span>
            </li>
            <li>
              <a href='https://kisspatent.com' target='_blank' rel="noreferrer">kisspatent.com</a>
              <span>AI-powered patent software to generate patents</span>
            </li>
            <li>
              <a href='https://safiullin.pro/ProblemSolver' target='_blank' rel="noreferrer">safiullin.pro/ProblemSolver</a>
              <span>TRIZ like text generation of solution strategies</span>
            </li>
            <li>
              <a href='https://everyca.io' target='_blank' rel="noreferrer">everyca.io</a>
              <span>TRIZ based basic contradiction tool</span>
            </li>
            <li>
              <a href='https://www.patentbots.com' target='_blank' rel="noreferrer">patentbots.com</a>
              <span>AI-enabled tools for patent prosecution</span>
            </li>
            <li>
              <a href='https://www.patentinspiration.com' target='_blank' rel="noreferrer">patentinspiration.com</a>
              <span>AI and unique text analysis innovation research in patents</span>
            </li>
            <li>
              <a href='https://rowanpatents.com' target='_blank' rel="noreferrer">rowanpatents.com</a>
              <span>AI-powered software that can automatically generate patents</span>
            </li>
            <li>
              <a href='https://whimsical.com/mind-maps' target='_blank' rel="noreferrer">whimsical.com/mind-maps</a>
              <span>Make mind-maps with Al-powered suggestions</span>
            </li>
            <li>
              <a href='https://www.dilation.ai' target='_blank' rel="noreferrer">dilation.ai</a>
              <span>Visual  AI for sorting products</span>
            </li>
            <li>
              <a href='https://consensus.app' target='_blank' rel="noreferrer">consensus.app</a>
              <span>Research papers with consensus AI</span>
            </li>
            <li>
              <a href='https://chatdoc.com' target='_blank' rel="noreferrer">chatdoc.com</a>
              <span>Upload research documents and analyse with chat interface</span>
            </li>
            <li>
              <a href='https://www.explainpaper.com' target='_blank' rel="noreferrer">explainpaper.com</a>
              <span>Highlight zones in paper and get an explanation of content</span>
            </li>
            <li>
              <a href='https://www.humata.ai' target='_blank' rel="noreferrer">humata.ai</a>
              <span>Ask AI anything about your uploaded research files</span>
            </li>
            <li>
              <a href='https://elicit.org' target='_blank' rel="noreferrer">elicit.org</a>
              <span>Ask any research question without uploading files</span>
            </li>
            <li>
              <a href='https://www.paper-digest.com' target='_blank' rel="noreferrer">paper-digest.com</a>
              <span>Summarizes research papers</span>
            </li>
            <li>
              <a href='https://www.mybib.com' target='_blank' rel="noreferrer">mybib.com</a>
              <span>Citation generator</span>
            </li>
          </ul>
        </section>
    )
}