export default function AvatarsResources(){
    return (
        <section id="avatars">
          <h3>Avatars</h3>
          <ul>
            <li>
              <a href='https://studio.d-id.com' target='_blank' rel="noreferrer">studio.d-id.com</a>
              <span>Real time face animation to create a human-like experience</span>
            </li>
            <li>
              <a href='https://askthee.vercel.app/' target='_blank' rel="noreferrer">askthee.vercel.app</a>
              <span>Ask big thinkers like Aristotle and Einstein anything you want</span>
            </li>
            <li>
              <a href='https://www.hereafter.ai/' target='_blank' rel="noreferrer">hereafter.ai</a>
              <span>An Al memory app that preserves your memories forever</span>
            </li>
            <li>
              <a href='https://lovo.ai/' target='_blank' rel="noreferrer">lovo.ai</a>
              <span>Realistic AI voices that captivate your audience</span>
            </li>
            <li>
              <a href='https://wtfdoesthiscompanydo.vercel.app/' target='_blank' rel="noreferrer">wtfdoesthiscompanydo.vercel.app</a>
              <span>Explains landing pages by just entering domain</span>
            </li>
          </ul>
        </section>
    )
}